import Layout from "@/lib/Layout/Layout";
import { footerData } from "./api/footerData";
import Image from "next/image";

const Page404 = () => {
  return (
    <>
      <Layout footerData={footerData}>
        <div className="flex justify-center">
          <Image
            src="https://assets.bonatra.com/package_detail/404.jpg"
            width={500}
            height={500}
            alt="404 page image"
          />
        </div>
      </Layout>
    </>
  );
};

export default Page404;
